import React, { useEffect, useState } from "react";
import "../styles/About.css";

import { CONTRACT_ADDRESS } from "../config/blockchain";
import axios from "axios";
import { BACKEND_URL } from "../config/backend";

const About = ({ mintResult }) => {
  const [totalMinted, setTotalMinted] = useState(0);

  const getTotalMinted = () => {
    axios
      .get(`${BACKEND_URL}/totalMinted`)
      .then((res) => {
        setTotalMinted(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTotalMinted();
  }, [mintResult]);

  return (
    <div className="about">
      <div className="about-text">
        <p className="about-paragraph">
          Painty is a community-driven art project.
        </p>
        <p className="about-paragraph">
          Upon mint, the artwork on the main canvas will be associated with your
          NFT. The original artist will receive royalties from secondary sales.
        </p>
        <p className="about-paragraph">
          Do not refresh or leave this page until token is successfully minted!
          Painty reserves the right to remove any artwork that is deemed
          unacceptable.
        </p>
        <p className="about-paragraph">
          The save button will temporarily store your artwork in the browser.
          Click on the thumbnail to put it back on the main canvas. This clears
          the previous canvas.
        </p>
        <p className="about-paragraph">Contract: {CONTRACT_ADDRESS}</p>
        <p className="about-paragraph">Cost: 1 AVAX</p>
        <p className="about-paragraph">Minted: {totalMinted} / 555</p>
      </div>
    </div>
  );
};

export default About;
