import { BACKEND_URL } from "../config/backend";

export async function clearSaved(_name) {
  const serverRes = await fetch(`${BACKEND_URL}/clear`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({ name: _name }),
  });

  const serverJsonResponse = await serverRes.json();
  console.log(`Server: ${JSON.stringify(serverJsonResponse)}`);
  return serverJsonResponse;
}
