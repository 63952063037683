const CHAIN_ID = "0xa86a";
const CONTRACT_ADDRESS = "0x0497230664700CB752f513d562E35cB5874F808A";
const EXPLORER_URL = "https://snowtrace.io/tx/";
const RPC_URL = "https://api.avax.network/ext/bc/C/rpc";

module.exports = {
  CHAIN_ID,
  CONTRACT_ADDRESS,
  EXPLORER_URL,
  RPC_URL,
};
