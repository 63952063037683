import "../styles/Footer.css";

const Footer = () => {
  return (
    <div className="Footer">
      <p>Painty © 2023</p>
    </div>
  );
};

export default Footer;
