const colors = [
  "black",
  "green",
  "yellow",
  "red",
  "blue",
  "purple",
  "grey",
  "brown",
];
const lineWidths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 25, 50, 100];
const width = 600;
const height = 600;
const offsetX = 409;
const offsetY = 128;

module.exports = {
  colors,
  lineWidths,
  width,
  height,
  offsetX,
  offsetY,
};
