import React, { useState, useRef, useEffect } from "react";
import "./App.css";

import {
  connectWallet,
  checkIfWalletIsConnected,
  checkCorrectNetwork,
} from "./utils/connect-wallet";

import { width, height } from "./config/draw";

import Navbar from "./components/Navbar";
import Draw from "./components/Draw";
import Toolbar from "./components/Toolbar";
import Gallery from "./components/Gallery";
import Footer from "./components/Footer";
import About from "./components/About";
import Saved from "./components/Saved";
import { sendSavedImage } from "./api/send-saved-image";
import { getSavedArtworks } from "./api/get-saved-artworks";
import { clearSaved } from "./api/clear-saved";
import LoadingOverlay from "./components/Loading";

const App = () => {
  // ------------Canvas-------------
  const canvasRef = useRef(null);
  const ctx = useRef(null);
  // -------------------------------

  // ---------Connect Wallet--------
  const [currentAccount, setCurrentAccount] = useState("");
  const [correctNetwork, setCorrectNetwork] = useState(false);
  // -------------------------------

  // ------------Mint---------------
  const [loading, setLoading] = useState(false);
  const [mintResult, setMintResult] = useState("");
  // -------------------------------

  // -----------Draw----------------
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [selectedLineWidth, setSelectedLineWidth] = useState(1);

  // -------------Undo--------------

  const undoArray = [];

  const handleUndoButton = () => {
    if (undoArray.length - 1 <= 0) {
      _clearCanvas();
    } else {
      undoArray.pop();
      ctx.current.putImageData(undoArray[undoArray.length - 1], 0, 0);
    }
  };

  // -------------------------------

  const _clearCanvas = () => {
    ctx.current.fillRect(0, 0, width, height);
    undoArray.length = 0;
  };

  const handleClearButton = () => {
    const shouldClear = window.confirm(
      "Are you sure you want to clear the canvas?"
    );
    if (shouldClear) {
      _clearCanvas();
    }
  };

  const [savedArtworks, setSavedArtworks] = useState([]);

  const handleSaveButton = async () => {
    if (savedArtworks.length > 9) {
      alert("Reached max save amount.");
      return;
    }

    const dataURL = canvasRef.current.toDataURL("image/png");

    const newSavedArtworks = [...savedArtworks, dataURL];
    setSavedArtworks(newSavedArtworks);

    await sendSavedImage(dataURL, currentAccount);
  };

  const loadSavedArtwork = (artwork) => {
    const canvas = canvasRef.current;
    ctx.current.clearRect(0, 0, canvas.width, canvas.height);
    const img = new Image();
    img.onload = () => {
      ctx.current.drawImage(img, 0, 0);
    };
    img.src = artwork;
  };

  useEffect(() => {
    async function fetchData() {
      if (currentAccount !== "") {
        try {
          const response = await getSavedArtworks(currentAccount);
          setSavedArtworks(response.artworks);
          console.log(response);
        } catch (error) {
          console.error("Error fetching saved artworks:", error);
        }
      }
    }
    fetchData();
  }, [currentAccount]);

  const handleClearSaved = async () => {
    const shouldClear = window.confirm("Are you sure you want to clear saved?");
    if (shouldClear) {
      try {
        const response = await clearSaved(currentAccount);
        setSavedArtworks([]);
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    }
  };
  // -------------------------------

  // ---------Connect Wallet--------
  const handleConnectWallet = async () => {
    const result = await connectWallet();
    if (result) {
      setCurrentAccount(result);
    }
  };

  const handleCheckIfWalletIsConnected = async () => {
    const result = await checkIfWalletIsConnected();
    if (result) {
      setCurrentAccount(result);
    }
  };

  const handleCheckCorrectNetwork = async () => {
    const result = await checkCorrectNetwork();
    setCorrectNetwork(result);
  };
  // -------------------------------

  // -----------On Change-----------
  useEffect(() => {
    handleCheckIfWalletIsConnected();
    handleCheckCorrectNetwork();
  }, []);

  const { ethereum } = window;
  if (ethereum) {
    window.ethereum.on("chainChanged", handleCheckCorrectNetwork);
    window.ethereum.on("accountsChanged", handleConnectWallet);
  }

  // -------------------------------

  return (
    <>
      <Navbar
        connectWallet={handleConnectWallet}
        currentAccount={currentAccount}
        correctNetwork={correctNetwork}
      />

      <Draw
        selectedColor={selectedColor}
        selectedLineWidth={selectedLineWidth}
        width={width}
        height={height}
        canvasRef={canvasRef}
        ctx={ctx}
        undoArray={undoArray}
      />

      <Toolbar
        setSelectedColor={setSelectedColor}
        selectedColor={selectedColor}
        setSelectedLineWidth={setSelectedLineWidth}
        selectedLineWidth={selectedLineWidth}
        handleUndoButton={handleUndoButton}
        handleClearButton={handleClearButton}
        currentAccount={currentAccount}
        correctNetwork={correctNetwork}
        setLoading={setLoading}
        loading={loading}
        canvasRef={canvasRef}
        mintResult={mintResult}
        setMintResult={setMintResult}
        handleSaveButton={handleSaveButton}
      />

      <Saved
        savedArtworks={savedArtworks}
        loadSavedArtwork={loadSavedArtwork}
        handleClearSaved={handleClearSaved}
      />
      {/* <Gallery /> */}
      <About mintResult={mintResult} />
      <Footer />

      {/* {loading ? <LoadingOverlay /> : null} */}
    </>
  );
};

export default App;
