import { BACKEND_URL } from "../config/backend";

export async function getSavedArtworks(user) {
  const serverRes = await fetch(`${BACKEND_URL}/stored/${user}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  });

  const serverJsonResponse = await serverRes.json();
  return serverJsonResponse;
}
