import { BACKEND_URL } from "../config/backend";

export async function sendSavedImage(imageData, user) {
  const serverRes = await fetch(`${BACKEND_URL}/store`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({ file: imageData, name: user }),
  });

  const serverJsonResponse = await serverRes.json();
  console.log(`Server: ${JSON.stringify(serverJsonResponse)}`);
  return serverJsonResponse;
}
