import "../styles/Toolbar.css";

import { createToken, uploadImage } from "../utils/handle-mint";
import { randomBytes, hexlify } from "ethers/lib/utils";

import { colors, lineWidths } from "../config/draw";
import { useEffect, useState } from "react";
import axios from "axios";
import { BACKEND_URL } from "../config/backend";

const Toolbar = ({
  setSelectedColor,
  selectedColor,
  setSelectedLineWidth,
  selectedLineWidth,
  handleUndoButton,
  handleClearButton,
  currentAccount,
  correctNetwork,
  setLoading,
  loading,
  canvasRef,
  mintResult,
  setMintResult,
  handleSaveButton,
}) => {
  const onSelectColor = (color) => {
    setSelectedColor(color);
  };

  const onSelectLineWidth = (lineWidth) => {
    setSelectedLineWidth(lineWidth);
  };

  // --------------------------------
  const handleCreateToken = async () => {
    setMintResult("");
    setLoading(true);
    const _name = randomBytes(32);
    const parsedName = hexlify(_name);
    const artRes = await uploadImage(canvasRef, parsedName);
    if (artRes) {
      const tokenRes = await createToken(_name);
      setMintResult(tokenRes);
      tokenRes[1] ? console.log(tokenRes[1]) : console.log(tokenRes[0]);
    } else {
      alert("Error: try again");
    }
    setLoading(false);
    // tokenRes[1] ? await uploadImage(canvasRef, parsedName) : console.log(tokenRes[0]);
  };

  const mintText = () => {
    if (loading) {
      return "Minting...";
    }
    // if (!currentAccount || !correctNetwork) {
    //   return "Connect";
    // }
    return "Mint";
  };

  const mintStatus = () => {
    if (mintResult) {
      return mintResult;
    }
    return "";
  };
  // --------------------------------

  const [isConnectedToApi, setIsConnectedToApi] = useState(false);

  const checkAPIStatus = () => {
    axios
      .get(`${BACKEND_URL}/`)
      .then((res) => {
        setIsConnectedToApi(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    checkAPIStatus();
  }, []);

  return (
    <>
      <div className="toolbar">
        <div className="toolbar-items">
          <select
            className="dropdown"
            value={selectedLineWidth}
            onChange={(e) => onSelectLineWidth(e.target.value)}
          >
            {lineWidths.map((lineWidth) => (
              <option key={lineWidth} value={lineWidth}>
                {lineWidth}
              </option>
            ))}
          </select>
          <input
            className="color-selector"
            type="color"
            value={selectedColor}
            onChange={(e) => onSelectColor(e.target.value)}
          />
          <button className="button" onClick={handleUndoButton}>
            Undo
          </button>
          <button className="button" onClick={handleClearButton}>
            Clear
          </button>
          <button
            className="button"
            onClick={handleSaveButton}
            disabled={
              !currentAccount || !correctNetwork || !isConnectedToApi || loading
            }
          >
            Save
          </button>
        </div>
      </div>
      <div className="toolbar-mint">
        <button
          className="toolbar-mint-button"
          onClick={handleCreateToken}
          disabled={
            !currentAccount || !correctNetwork || !isConnectedToApi || loading
          }
        >
          {mintText()}
        </button>
      </div>
      <div className="status">
        <p>{mintStatus()}</p>
      </div>
    </>
  );
};

export default Toolbar;
