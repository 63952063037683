import React from "react";
import "../styles/Saved.css";

const Saved = ({ savedArtworks, loadSavedArtwork, handleClearSaved }) => {
  return (
    <div className="saved">
      <h1 className="saved-title">Saved</h1>
      <div className="saved-container">
        <div className="image-scroll-container">
          {savedArtworks.map((artwork, index) => (
            <div
              key={index}
              className="saved-image"
              onClick={() => loadSavedArtwork(artwork)}
            >
              <img src={artwork} alt={`Artwork ${index}`} />
            </div>
          ))}
        </div>
      </div>
      <div className="button-container-saved">
        <button
          className="clear-saved-button"
          onClick={handleClearSaved}
          disabled={savedArtworks.length < 1}
        >
          Clear saved
        </button>
      </div>
    </div>
  );
};

export default Saved;
