import { CHAIN_ID, EXPLORER_URL, RPC_URL } from "../config/blockchain";

export async function checkIfWalletIsConnected() {
  const { ethereum } = window;
  if (ethereum) {
    console.log("Ethereum object: ", ethereum);
  } else {
    console.log("No wallet found");
  }

  const accounts = await ethereum.request({ method: "eth_accounts" });

  if (accounts.length !== 0) {
    console.log("Authorized account: ", accounts[0]);
    // setCurrentAccount(accounts[0]);
    return accounts[0];
  } else {
    console.log("No authorized account found");
    return;
  }
}

export async function connectWallet() {
  try {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Metamask not detected");
      return;
    }

    let chainId = await ethereum.request({ method: "eth_chainId" });
    console.log("Connected to chain: ", chainId);

    if (chainId !== CHAIN_ID) {
      await switchEthereumChain();
    }

    const accounts = await ethereum.request({ method: "eth_requestAccounts" });

    console.log("Found account: ", accounts[0]);
    // setCurrentAccount(accounts[0])
    return accounts[0];
  } catch (err) {
    console.log("Error: ", err);
    return;
  }
}

const switchEthereumChain = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: CHAIN_ID }],
    });
  } catch (e) {
    if (e.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: CHAIN_ID,
              chainName: "Avalanche",
              nativeCurrency: {
                name: "Avalanche",
                symbol: "AVAX",
                decimals: 18,
              },
              blockExplorerUrls: [EXPLORER_URL],
              rpcUrls: [RPC_URL],
            },
          ],
        });
      } catch (error) {
        console.error(error);
      }
    }
    // console.error(e)
  }
};

export async function checkCorrectNetwork() {
  const { ethereum } = window;
  let chainId = await ethereum.request({ method: "eth_chainId" });
  console.log("Connected to chain: ", chainId);

  if (chainId !== CHAIN_ID) {
    // setCorrectNetwork(false);
    return false;
  } else {
    // setCorrectNetwork(true);
    return true;
  }
}
