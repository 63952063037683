import React, { useState, useEffect } from "react";
import "../styles/Loading.css";

const LoadingOverlay = () => {
  const [countdown, setCountdown] = useState(15);
  const [active, setActive] = useState(true);

  const handleLoadingClose = () => {
    setActive(false);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [countdown]);

  return (
    <>
      {active ? (
        <div className="loading-overlay">
          <div className="loader"></div>
          <p>Loading...</p>
          {countdown < 1 ? (
            <button onClick={handleLoadingClose}>X</button>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default LoadingOverlay;
