import { useState, useEffect, useCallback } from "react";
import "../styles/Draw.css";

const Draw = ({
  selectedColor,
  selectedLineWidth,
  width,
  height,
  canvasRef,
  ctx,
  undoArray,
}) => {
  const [mouseOnCanvas, setMouseOnCanvas] = useState();
  const [mouseHeldOffCanvas, setMouseHeldOffCanvas] = useState();
  const [mouseDown, setMouseDown] = useState(false);
  const [lastPosition, setPosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (canvasRef.current) {
      ctx.current = canvasRef.current.getContext("2d");
      ctx.current.fillStyle = "white";
      ctx.current.fillRect(0, 0, width, height);
    }
    // eslint-disable-next-line
  }, []);

  const draw = useCallback(
    (x, y) => {
      if (mouseDown) {
        ctx.current.beginPath();
        ctx.current.strokeStyle = selectedColor;
        ctx.current.lineWidth = selectedLineWidth;
        ctx.current.lineJoin = "round";
        ctx.current.moveTo(lastPosition.x, lastPosition.y);
        ctx.current.lineTo(x, y);
        ctx.current.closePath();
        ctx.current.stroke();

        setPosition({
          x,
          y,
        });
      }
      // eslint-disable-next-line
    },
    [lastPosition, mouseDown, selectedColor, setPosition]
  );

  const _undoArrayUpdate = () => {
    undoArray.push(ctx.current.getImageData(0, 0, width, height));
  };

  const handleUndoArrayUpdate = (type) => {
    if (type === "up") {
      setMouseDown(false);
      if (mouseOnCanvas && !mouseHeldOffCanvas) {
        _undoArrayUpdate();
      }
    } else if (type === "leave") {
      setMouseOnCanvas(false);
      if (mouseDown) {
        setMouseHeldOffCanvas(true);
        setMouseDown(false);
        _undoArrayUpdate();
      }
    } else if (type === "enter") {
      setMouseOnCanvas(true);
      if (!mouseDown) {
        setMouseHeldOffCanvas(false);
      }
    }
  };

  const onMouseMove = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    draw(e.clientX - rect.left, e.clientY - rect.top);
  };

  const onMouseDown = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    setPosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
    setMouseDown(true);
  };

  const onMouseUp = (e) => {
    handleUndoArrayUpdate("up");
  };

  const onMouseLeave = (e) => {
    handleUndoArrayUpdate("leave");
  };

  const onMouseEnter = (e) => {
    handleUndoArrayUpdate("enter");
  };

  return (
    <div className="Draw">
      <canvas
        style={{
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
        }}
        width={width}
        height={height}
        ref={canvasRef}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </div>
  );
};

export default Draw;
