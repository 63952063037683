import "../styles/Navbar.css";

import discordIcon from "../assets/discord.png";
import joepegsIcon from "../assets/joepegs.png";
import { CONTRACT_ADDRESS } from "../config/blockchain";

const discordLink = "https://discord.gg/bmSpKs2U";
const joepegsLink = "https://joepegs.com";

const Navbar = ({ connectWallet, currentAccount, correctNetwork }) => {
  const getText = () => {
    if (currentAccount && correctNetwork) {
      return "0x..." + currentAccount.slice(currentAccount.length - 4, 42);
    }
    return "Connect";
  };

  return (
    <div className="Navbar">
      <div className="socials">
        <a href={discordLink} target="_blank" rel="noopener noreferrer">
          <img src={discordIcon} alt="discord" className="icon" />
        </a>
        <a
          href={`${joepegsLink}/collections/avalanche/${CONTRACT_ADDRESS.toLowerCase()}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={joepegsIcon} alt="joepegs" className="icon" />
        </a>
      </div>
      <h1 className="title">Painty</h1>
      <button className="connect-wallet-btn" onClick={connectWallet}>
        {getText()}
      </button>
    </div>
  );
};

export default Navbar;
