import { ethers } from "ethers";
import abi from "../abi/abi.json";
import { CONTRACT_ADDRESS, EXPLORER_URL } from "../config/blockchain";
import { sendImage } from "../api/send-image";

export async function createToken(_name) {
  const txnObj = ["", ""];
  try {
    const { ethereum } = window;

    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(CONTRACT_ADDRESS, abi.abi, signer);

      const cost = await contract.cost();
      console.log({ cost });

      const overrides = {
        value: ethers.utils.parseEther("1"),
      };

      let mintTx = await contract.mint(_name, overrides);
      console.log("hash: ", mintTx.hash);

      // wait for txn success
      await mintTx.wait();

      txnObj[1] = `Success: ${EXPLORER_URL}${mintTx.hash}`;
      return txnObj;
    } else {
      txnObj[0] = "Unknown Error";
      return txnObj;
    }
  } catch (err) {
    console.log(err);
    txnObj[0] = err.message;
    return txnObj;
  }
}

export async function uploadImage(canvasRef, _name) {
  const imageData = canvasRef.current.toDataURL("image/png");

  const res = await sendImage(imageData, _name);

  return res;
}
